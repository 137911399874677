import React from "react"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"

import StaticPage from "../../components/StaticPage/StaticPage"
import {
  createOverlay,
  createContentList,
} from "../../contentfunctions/guarantee.js"

import Carousel1 from "../../images/guarantee/guarantee-min.jpeg"

const GuaranteePage = ({ location }) => {
  Utils.NavigateToBrowserLanguage("es", "/guarantee", location.state)
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(
          data.guaranteeContent.item.content
        )
        const img = {
          src: Carousel1,
          altText: contentMap.seo_title.value,
          caption: contentMap.seo_description.value,
        }
        return (
          <StaticPage
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            img={img}
            originPage="guarantee"
            language="es"
            location={location}
            contentMap={contentMap}
            commonContentMap={commonContentMap}
            breadCrumbs={["menu_home", "menu_guarantee"]}
            createContentFun={createContentList}
            createOverlayFun={createOverlay}
            keywords={Utils.GetKeywordsFromTags(
              data.guaranteeContent.item.tags
            )}
          />
        )
      }}
    />
  )
}

export default GuaranteePage

const pageQuery = graphql`
  {
    guaranteeContent: guaranteeContentEses {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentEses {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
